import React, {Component} from 'react';
import axios from 'axios';

import {
	Modal, Table, Button, Row, Col, Alert
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import LayoutPrivate from '../../../components/Layout/Private';

import {extendToken, setTitle} from '../../../util/common';

class ListIntegration extends Component {
	state = {
		success: false,
		proceed: false,
		error: false,
		successDeletion: false,
		proceedDeletion: false,
		errorDeletion: false,
		deleteId: null,
		data: [],
		order: 'id',
		asc: false
	};

	componentDidMount() {
		extendToken();

		setTitle(['Integracje']);

		this.loadData();
	}

	sortable = (type) => {
		this.setState((prevState) => ({
			order: type,
			asc: prevState.order === type ? !prevState.asc : prevState.asc
		}), () => {
			this.loadData();
		});
	};

	loadData = () => {
		this.setState({
			error: false,
			proceed: true,
			success: false
		});

		const {order, asc} = this.state;

		axios.get(`${process.env.REACT_APP_API_URL}/integration/?order=${order}&asc=${asc}`)
			.then((res) => {
				if (res.success) {
					this.setState({
						error: false,
						proceed: false,
						success: true
					});

					this.setState({
						data: res.message
					});

					setTimeout(() => {
						this.setState({
							success: false
						});
					}, 2500);
				} else {
					this.setState({
						error: true,
						proceed: false,
						success: false
					});
				}
			})
			.catch(() => {
				this.setState({
					error: true,
					proceed: false,
					success: false
				});
			});

		return true;
	};

	handleDelete = (deleteId) => {
		this.setState({
			deleteId
		});
	};

	handleDeleteConfirmed = () => {
		const {deleteId, proceedDeletion, proceed} = this.state;

		if (proceedDeletion || proceed) {
			return false;
		}

		this.setState({
			deleteId: null,
			errorDeletion: false,
			proceedDeletion: true,
			successDeletion: false
		});

		axios.delete(`${process.env.REACT_APP_API_URL}/integration/${deleteId}`)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorDeletion: false,
						proceedDeletion: false,
						successDeletion: true
					});

					this.loadData();

					setTimeout(() => {
						this.setState({
							successDeletion: false
						});
					}, 2000);
				} else {
					this.setState({
						errorDeletion: true,
						proceedDeletion: false,
						successDeletion: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorDeletion: true,
					proceedDeletion: false,
					successDeletion: false
				});
			});

		return true;
	};

	handleDeleteClose = () => {
		this.setState({
			deleteId: null
		});
	};

	render() {
		const {history} = this.props;

		const {
			data, success, proceed, error, successDeletion, proceedDeletion, errorDeletion, deleteId, order, asc
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col>
						<h2 style={{marginBottom: '2rem'}}>Integracje</h2>
						{
							errorDeletion && (
								<Alert variant="danger">
									Błąd połączenia z serwerem, obiekt nie został usunięty.
								</Alert>
							)
						}
						{
							proceedDeletion && (
								<Alert variant="warning">
									Trwa usuwanie wybranego obiektu, proszę czekać...
								</Alert>
							)
						}
						{
							successDeletion && (
								<Alert variant="success">
									Obiekt został usunięty poprawnie.
								</Alert>
							)
						}
						{
							error && (
								<Alert variant="danger">
									Błąd połączenia z serwerem.
								</Alert>
							)
						}
						{
							proceed && (
								<Alert variant="warning">
									Generowanie widoku w toku... System może częściowo nie odpowiadać.
								</Alert>
							)
						}
						{
							success && (
								<Alert variant="success">
									Gotowe, możesz pracować :)
								</Alert>
							)
						}
						<Table striped bordered hover>
							<thead>
								<tr>
									<th colSpan="8" className="buttons">
										<Button variant="primary" href="/integration/add">Dodaj nową integrację</Button>
									</th>
								</tr>
								<tr>
									<th className="sortable" onClick={() => this.sortable('id')}>
										#
										{order === 'id' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('name')}>
										Nazwa
										{order === 'name' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('integration_mode_type_name')}>
										Tryb
										{order === 'integration_mode_type_name' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('created')}>
										Utworzono
										{order === 'created' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('updated')}>
										Zaktualizowano
										{order === 'updated' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('user_firstname')}>
										Autor
										{order === 'user_firstname' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th />{/* eslint-disable-line jsx-a11y/control-has-associated-label */}
								</tr>
							</thead>
							<tbody>
								{
									data.map((item) => (
										<tr key={`table_${item.id}`}>
											<td>{item.id}</td>
											<td>{item.name}</td>
											<td>{item.integration_mode_type_name}</td>
											<td>{item.created}</td>
											<td>{item.updated}</td>
											<td>
												{item.user_firstname} {item.user_lastname}
											</td>
											<td>
												<button type="button" className="btn btn-sm btn-success" onClick={() => history.push(`/integration/${item.id}`)}>Edytuj</button>
											&nbsp;
												<button type="button" className="btn btn-sm btn-danger" onClick={() => this.handleDelete(item.id)}>Usuń</button>
											</td>
										</tr>
									))
								}
							</tbody>
						</Table>
					</Col>
				</Row>
				<Modal show={deleteId !== null} onHide={this.handleDeleteClose}>
					<Modal.Header closeButton>
						<Modal.Title>Potwierdź akcję</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>
							Naprawdę chcesz usunąć integrację #{deleteId}? To spowoduje, że wszystkie powiązane zbiórki przestaną działać i utracisz możliwość zbierania pieniędzy. Same pieniądze zostaną na Twoim koncie.
						</p>
						<Alert variant="warning">
							<strong>Zatrzymaj się na chwilę!</strong> Ta operacja jest nieodwracalna z poziomu panelu administratora. Może ją przywrócić tylko administrator systemu.
						</Alert>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="success" onClick={this.handleDeleteConfirmed}>
							Tak, chcę.
						</Button>
						<Button variant="danger" onClick={this.handleDeleteClose}>
							Nie, nie chcę.
						</Button>
					</Modal.Footer>
				</Modal>
			</LayoutPrivate>
		);
	}
}

ListIntegration.propTypes = {
	history: PropTypes.object.isRequired
};

export default ListIntegration;
