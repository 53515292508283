import React, {Component} from 'react';
import axios from 'axios';

import {
	Form, Button, Row, Col, Alert
} from 'react-bootstrap';
import LayoutPrivate from '../../../components/Layout/Private';

import {extendToken, setTitle} from '../../../util/common';

class AddAccount extends Component {
	state = {
		success: false,
		proceed: false,
		error: false,
		email: '',
		password: '',
		firstname: '',
		lastname: ''
	};

	componentDidMount() {
		extendToken();

		setTitle(['Konta użytkowników', 'Dodaj nowe konto']);
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const {
			email, password, firstname, lastname, proceed
		} = this.state;
		if (proceed) return false;

		if (email === '' || password === '') {
			this.setState({
				error: true,
				proceed: false,
				success: false
			});

			return false;
		}

		const data = {
			email,
			password,
			firstname,
			lastname
		};

		this.setState({
			error: false,
			proceed: true,
			success: false
		});

		axios.post(`${process.env.REACT_APP_API_URL}/account/create`, data)
			.then((res) => {
				if (res.success) {
					this.setState({
						error: false,
						proceed: false,
						success: true
					});

					setTimeout(() => {
						window.location.replace('/account');
					}, 2000);
				} else {
					this.setState({
						error: true,
						proceed: false,
						success: false
					});
				}
			})
			.catch(() => {
				this.setState({
					error: true,
					proceed: false,
					success: false
				});
			});

		return true;
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	render() {
		const {success, proceed, error} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col md={{span: 6, offset: 3}}>
						<h2 style={{marginBottom: '2rem'}}>Nowe konto użytkownika</h2>
						{
							error && (
								<Alert variant="danger">
									Popraw dane w formularzu, gdyż wystąpił błąd.
								</Alert>
							)
						}
						{
							proceed && (
								<Alert variant="warning">
									Zakładanie konta w toku...
								</Alert>
							)
						}
						{
							success && (
								<Alert variant="success">
									Konto utworzone, trwa przekierowanie...
								</Alert>
							)
						}
						<Form onSubmit={this.handleSubmit}>
							<Row>
								<Col lg={{span: 6}}>
									<Form.Group controlId="firstname">
										<Form.Label>Imię</Form.Label>
										<Form.Control name="firstname" type="text" placeholder="Wpisz imię" onChange={this.handleChange} />
									</Form.Group>
								</Col>
								<Col lg={{span: 6}}>
									<Form.Group controlId="lastname">
										<Form.Label>Nazwisko</Form.Label>
										<Form.Control name="lastname" type="text" placeholder="Wpisz nazwisko" onChange={this.handleChange} />
									</Form.Group>
								</Col>
							</Row>

							<Form.Group controlId="email">
								<Form.Label>E-mail</Form.Label>
								<Form.Control name="email" type="email" placeholder="Wpisz e-mail" onChange={this.handleChange} />
							</Form.Group>

							<Form.Group controlId="password">
								<Form.Label>Hasło</Form.Label>
								<Form.Control name="password" type="password" placeholder="Wpisz hasło" onChange={this.handleChange} />
							</Form.Group>

							<Alert variant="warning">
								<strong>Zatrzymaj się</strong> na chwilę :)
								<br />
								Jeżeli utworzysz konto użytkownikowi to ten użytkownik będzie miał dostęp do całego panelu. Prawo do odbierania dostępu posiada tylko super administrator i jest one nadawane przez administratora systemu.
							</Alert>

							<Button variant="primary" type="submit">
								Utwórz nowe konto
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

export default AddAccount;
