import React, {Component} from 'react';
import axios from 'axios';

import {
	Form, Button, Row, Col, Alert
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import LayoutPrivate from '../../../components/Layout/Private';

import {extendToken, setTitle} from '../../../util/common';

class AddCharity extends Component {
	state = {
		success: false,
		proceed: false,
		error: false,
		email: '',
		price: '',
		created: null
	};

	componentDidMount() {
		extendToken();

		const {charityActionId} = this.props.match.params;

		setTitle(['Zbiórki', `Zbiórka #${charityActionId}`, 'Dodaj nową wpłatę']);
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const {
			email, price, created, proceed
		} = this.state;
		if (proceed) return false;

		if (price === '' || email === '') {
			this.setState({
				error: true,
				proceed: false,
				success: false
			});

			return false;
		}

		const {charityActionId} = this.props.match.params;

		const data = {
			charity_action_id: charityActionId,
			price,
			email,
			created
		};

		this.setState({
			error: false,
			proceed: true,
			success: false
		});

		axios.post(`${process.env.REACT_APP_API_URL}/charity/add`, data)
			.then((res) => {
				if (res.success) {
					this.setState({
						error: false,
						proceed: false,
						success: true
					});

					setTimeout(() => {
						window.location.replace(`/charity/${charityActionId}`);
					}, 2000);
				} else {
					this.setState({
						error: true,
						proceed: false,
						success: false
					});
				}
			})
			.catch(() => {
				this.setState({
					error: true,
					proceed: false,
					success: false
				});
			});

		return true;
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	render() {
		const {charityActionId} = this.props.match.params;

		const {success, proceed, error} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col md={{span: 6, offset: 3}}>
						<h2 style={{marginBottom: '2rem'}}>
							Nowa wpłata dla zbiórki #
							{charityActionId}
						</h2>
						{
							error && (
								<Alert variant="danger">
									Popraw dane w formularzu, gdyż wystąpił błąd.
								</Alert>
							)
						}
						{
							proceed && (
								<Alert variant="warning">
									Dodawanie wpłaty w toku...
								</Alert>
							)
						}
						{
							success && (
								<Alert variant="success">
									Wpłata utworzona, trwa przekierowanie...
								</Alert>
							)
						}
						<Form onSubmit={this.handleSubmit}>
							<Form.Group controlId="email">
								<Form.Label>E-mail sponsora</Form.Label>
								<Form.Control name="email" type="email" placeholder="Wpisz e-mail sponsora" onChange={this.handleChange} />
							</Form.Group>

							<Form.Group controlId="price">
								<Form.Label>Kwota (zł)</Form.Label>
								<Form.Control name="price" type="number" placeholder="Wprowadź kwotę" onChange={this.handleChange} />
							</Form.Group>

							<Form.Group controlId="created">
								<Form.Label>Data wpłaty (opcjonalnie)</Form.Label>
								<Form.Control name="created" type="date" placeholder="Wprowadź datę" onChange={this.handleChange} />
							</Form.Group>

							<Button variant="primary" type="submit">
								Utwórz nową wpłatę w tej zbiórce
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

AddCharity.propTypes = {
	match: PropTypes.object.isRequired
};

export default AddCharity;
