import axios from 'axios';

export const extendToken = () => {
	axios.get(`${process.env.REACT_APP_API_URL}/user/extend`)
		.then((res) => {
			if (res.success) {
				window.localStorage.setItem('token', res.message.token);
			}
		});

	return false;
};

export const generateCharityType = (type) => {
	switch (type) {
		case 'SINGLE':
			return 'Jednorazowa';

		case 'INIT':
			return 'Inicjalizacyjna';

		case 'FUTURE':
			return 'Odnowiona';

		case 'IMPORTED':
			return 'Zaimportowana';

		default:
			return 'Niezdefiniowana';
	}
};

export const setTitle = (els) => {
	document.title = `${els.join(' - ')} | ${process.env.REACT_APP_NAME} v${process.env.REACT_APP_VERSION}`;
};
