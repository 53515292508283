import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import Login from './scenes/public/Login';
import ForgotPassword from './scenes/public/ForgotPassword';

import ListActiveSponsorship from './scenes/private/Sponsorship/ListActive';
import ListUnactiveSponsorship from './scenes/private/Sponsorship/ListUnactive';

import ListCharityAction from './scenes/private/CharityAction/List';
import AddCharityAction from './scenes/private/CharityAction/Add';
import EditCharityAction from './scenes/private/CharityAction/Edit';

import ListCharity from './scenes/private/Charity/List';
import AllCharity from './scenes/private/Charity/All';
import AddCharity from './scenes/private/Charity/Add';
import EditCharity from './scenes/private/Charity/Edit';
import ImportCharity from './scenes/private/Charity/Import';

import ListIntegration from './scenes/private/Integration/List';
import AddIntegration from './scenes/private/Integration/Add';
import EditIntegration from './scenes/private/Integration/Edit';

import ListAccount from './scenes/private/Account/List';
import AddAccount from './scenes/private/Account/Add';
import ProfileAccount from './scenes/private/Account/Profile';

const App = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path="/" component={Login} />
			<Route exact path="/forgot-password" component={ForgotPassword} />

			<Route exact path="/sponsorship" component={ListActiveSponsorship} />
			<Route exact path="/sponsorship/unactive" component={ListUnactiveSponsorship} />

			<Route exact path="/charity_action" component={ListCharityAction} />
			<Route exact path="/charity_action/add" component={AddCharityAction} />
			<Route exact path="/charity_action/:charityActionId" component={EditCharityAction} />

			<Route exact path="/charity/all" component={AllCharity} />
			<Route exact path="/charity/edit/:charityId" component={EditCharity} />
			<Route exact path="/charity/:charityActionId" component={ListCharity} />
			<Route exact path="/charity/:charityActionId/add" component={AddCharity} />
			<Route exact path="/charity/:charityActionId/import" component={ImportCharity} />

			<Route exact path="/integration" component={ListIntegration} />
			<Route exact path="/integration/add" component={AddIntegration} />
			<Route exact path="/integration/:integrationId" component={EditIntegration} />

			<Route exact path="/account" component={ListAccount} />
			<Route exact path="/account/add" component={AddAccount} />
			<Route exact path="/account/profile" component={ProfileAccount} />
		</Switch>
	</BrowserRouter>
);

export default App;
