import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from './App';
import * as serviceWorker from './serviceWorker';

axios.defaults.headers.common.Authorization = `Bearer ${window.localStorage.getItem('token')}`;

axios.interceptors.response.use((response) => {
	if (response.data.success === false && response.data.message === 'UNAUTHORISED' && window.localStorage.getItem('token')) {
		window.localStorage.removeItem('token');
		window.location.replace('/');
	}

	return response.data;
}, () => {
	window.localStorage.removeItem('token');
	window.location.replace('/');
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
