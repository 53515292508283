import React, {Component} from 'react';
import axios from 'axios';

import {
	Modal, Table, Button, Row, Col, Alert
} from 'react-bootstrap';
import LayoutPrivate from '../../../components/Layout/Private';

import {extendToken, setTitle} from '../../../util/common';

class ListAccount extends Component {
	state = {
		successFetching: false,
		proceedFetching: false,
		errorFetching: false,
		successPermissions: false,
		proceedPermissions: false,
		errorPermissions: false,
		successDeletion: false,
		proceedDeletion: false,
		errorDeletion: false,
		userId: null,
		superUser: false,
		deleteId: null,
		data: [],
		order: 'created',
		asc: false
	};

	componentDidMount() {
		extendToken();

		setTitle(['Konta użytkowników']);

		this.setState({
			successPermissions: false,
			proceedPermissions: true,
			errorPermissions: false
		});

		axios.get(`${process.env.REACT_APP_API_URL}/account/me`)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorPermissions: false,
						proceedPermissions: false,
						successPermissions: true
					});

					this.setState({
						superUser: res.message.super === '1',
						userId: res.message.id
					});

					setTimeout(() => {
						this.setState({
							successPermissions: false
						});
					}, 2500);
				} else {
					this.setState({
						errorPermissions: true,
						proceedPermissions: false,
						successPermissions: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorPermissions: true,
					proceedPermissions: false,
					successPermissions: false
				});
			});

		this.loadData();
	}

	sortable = (type) => {
		this.setState((prevState) => ({
			order: type,
			asc: prevState.order === type ? !prevState.asc : prevState.asc
		}), () => {
			this.loadData();
		});
	};

	loadData = () => {
		this.setState({
			errorFetching: false,
			proceedFetching: true,
			successFetching: false
		});

		const {order, asc} = this.state;

		axios.get(`${process.env.REACT_APP_API_URL}/account/?order=${order}&asc=${asc}`)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorFetching: false,
						proceedFetching: false,
						successFetching: true
					});

					this.setState({
						data: res.message
					});

					setTimeout(() => {
						this.setState({
							successFetching: false
						});
					}, 2500);
				} else {
					this.setState({
						errorFetching: true,
						proceedFetching: false,
						successFetching: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorFetching: true,
					proceedFetching: false,
					successFetching: false
				});
			});

		return true;
	};

	handleDelete = (deleteId) => {
		this.setState({
			deleteId
		});
	};

	handleDeleteConfirmed = () => {
		const {deleteId, proceedDeletion, proceed} = this.state;

		if (proceedDeletion || proceed) {
			return false;
		}

		this.setState({
			deleteId: null,
			errorDeletion: false,
			proceedDeletion: true,
			successDeletion: false
		});

		axios.delete(`${process.env.REACT_APP_API_URL}/account/${deleteId}`)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorDeletion: false,
						proceedDeletion: false,
						successDeletion: true
					});

					this.loadData();

					setTimeout(() => {
						this.setState({
							successDeletion: false
						});
					}, 2000);
				} else {
					this.setState({
						errorDeletion: true,
						proceedDeletion: false,
						successDeletion: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorDeletion: true,
					proceedDeletion: false,
					successDeletion: false
				});
			});

		return true;
	};

	handleDeleteClose = () => {
		this.setState({
			deleteId: null
		});
	};

	render() {
		const {
			userId, deleteId, superUser, data, successFetching, proceedFetching, errorFetching, successDeletion, proceedDeletion, errorDeletion, successPermissions, proceedPermissions, errorPermissions, order, asc
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col>
						<h2 style={{marginBottom: '2rem'}}>Konta użytkowników</h2>
						{
							errorDeletion && (
								<Alert variant="danger">
									Błąd połączenia z serwerem, obiekt nie został usunięty.
								</Alert>
							)
						}
						{
							proceedDeletion && (
								<Alert variant="warning">
									Trwa usuwanie wybranego obiektu, proszę czekać...
								</Alert>
							)
						}
						{
							successDeletion && (
								<Alert variant="success">
									Obiekt został usunięty poprawnie.
								</Alert>
							)
						}
						{
							(errorFetching || errorPermissions) && (
								<Alert variant="danger">
									Błąd połączenia z serwerem.
								</Alert>
							)
						}
						{
							(proceedFetching || proceedPermissions) && (
								<Alert variant="warning">
									Generowanie widoku w toku... System może częściowo nie odpowiadać.
								</Alert>
							)
						}
						{
							(successFetching || successPermissions) && (
								<Alert variant="success">
									Gotowe, możesz pracować :)
								</Alert>
							)
						}
						{
							superUser ? (
								<Table striped bordered hover>
									<thead>
										<tr>
											<th colSpan="7" className="buttons">
												<Button variant="primary" href="/account/add">Dodaj nowe konto</Button>
											</th>
										</tr>
										<tr>
											<th className="sortable" onClick={() => this.sortable('id')}>
												#
												{order === 'id' && (asc ? '[↑]' : '[↓]')}
											</th>
											<th className="sortable" onClick={() => this.sortable('firstname')}>
												Właściciel
												{order === 'firstname' && (asc ? '[↑]' : '[↓]')}
											</th>
											<th className="sortable" onClick={() => this.sortable('email')}>
												E-mail
												{order === 'email' && (asc ? '[↑]' : '[↓]')}
											</th>
											<th className="sortable" onClick={() => this.sortable('super')}>
												Typ konta
												{order === 'super' && (asc ? '[↑]' : '[↓]')}
											</th>
											<th className="sortable" onClick={() => this.sortable('created')}>
												Utworzono
												{order === 'created' && (asc ? '[↑]' : '[↓]')}
											</th>
											<th className="sortable" onClick={() => this.sortable('updated')}>
												Zaktualizowano
												{order === 'updated' && (asc ? '[↑]' : '[↓]')}
											</th>
											<th />{/* eslint-disable-line jsx-a11y/control-has-associated-label */}
										</tr>
									</thead>
									<tbody>
										{
											data.map((item) => (
												<tr key={`table_${item.id}`}>
													<td>{item.id}</td>
													<td>
														{item.firstname} {item.lastname}
													</td>
													<td>{item.email}</td>
													<td>{item.super === '1' ? 'Administrator' : 'Użytkownik'}</td>
													<td>{item.created}</td>
													<td>{item.updated}</td>
													<td>{userId !== item.id && <button type="button" className="btn btn-sm btn-danger" onClick={() => this.handleDelete(item.id)}>Usuń</button>}</td>
												</tr>
											))
										}
									</tbody>
								</Table>
							) : (
								<Table striped bordered hover>
									<thead>
										<tr>
											<th colSpan="5" className="buttons">
												<Button variant="primary" href="/account/add">Dodaj nowe konto</Button>
											</th>
										</tr>
										<tr>
											<th className="sortable" onClick={() => this.sortable('id')}>
												#
												{order === 'id' && (asc ? '[↑]' : '[↓]')}
											</th>
											<th className="sortable" onClick={() => this.sortable('firstname')}>
												Właściciel
												{order === 'firstname' && (asc ? '[↑]' : '[↓]')}
											</th>
											<th className="sortable" onClick={() => this.sortable('email')}>
												E-mail
												{order === 'email' && (asc ? '[↑]' : '[↓]')}
											</th>
											<th className="sortable" onClick={() => this.sortable('created')}>
												Utworzono
												{order === 'created' && (asc ? '[↑]' : '[↓]')}
											</th>
											<th className="sortable" onClick={() => this.sortable('updated')}>
												Zaktualizowano
												{order === 'updated' && (asc ? '[↑]' : '[↓]')}
											</th>
										</tr>
									</thead>
									<tbody>
										{
											data.map((item) => (
												<tr key={`table_${item.id}`}>
													<td>{item.id}</td>
													<td>
														{item.firstname} {item.lastname}
													</td>
													<td>{item.email}</td>
													<td>{item.created}</td>
													<td>{item.updated}</td>
												</tr>
											))
										}
									</tbody>
								</Table>
							)
						}
						<Modal show={deleteId !== null} onHide={this.handleDeleteClose}>
							<Modal.Header closeButton>
								<Modal.Title>Potwierdź akcję</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<p>
									Naprawdę chcesz usunąć konto #
									{deleteId}
									? Żadne akcje wykonane przez tego użytkownika nie zostaną skasowane, ale konto nie będzie mogło być ponownie utworzone.
								</p>
								<Alert variant="warning">
									<strong>Zatrzymaj się na chwilę!</strong> Ta operacja jest nieodwracalna z poziomu panelu administratora. Może ją przywrócić tylko administrator systemu.
								</Alert>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="success" onClick={this.handleDeleteConfirmed}>
									Tak, chcę.
								</Button>
								<Button variant="danger" onClick={this.handleDeleteClose}>
									Nie, nie chcę.
								</Button>
							</Modal.Footer>
						</Modal>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

export default ListAccount;
