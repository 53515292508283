import React from 'react';
import {Container} from 'react-bootstrap';
import PropTypes from 'prop-types';
import NavPrivate from '../Nav/Private';

const LayoutPrivate = (props) => {
	const {children} = props;

	return (
		<>
			<NavPrivate />
			<Container style={{marginTop: '3rem'}}>
				{children}
			</Container>
		</>
	);
};

LayoutPrivate.propTypes = {
	children: PropTypes.node.isRequired
};

export default LayoutPrivate;
