import React, {Component} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {Editor} from '@tinymce/tinymce-react';

import {
	Form, Button, Row, Col, Alert
} from 'react-bootstrap';
import LayoutPrivate from '../../../components/Layout/Private';

import {extendToken, setTitle} from '../../../util/common';

class EditCharityAction extends Component {
	state = {
		initialized: false,
		successFetch: false,
		proceedFetch: false,
		errorFetch: false,
		successUpdate: false,
		proceedUpdate: false,
		errorUpdate: false,
		name: '',
		widgetText: '',
		shortDescription: '',
		description: '',
		now: '0',
		goal: '',
		renewable: false,
		expired: ''
	};

	componentDidMount() {
		extendToken();

		const {charityActionId} = this.props.match.params;

		setTitle(['Zbiórki', `Zbiórka #${charityActionId}`, 'Edytuj']);

		this.loadIntegrations();

		this.setState({
			errorFetch: false,
			proceedFetch: true,
			successFetch: false
		});

		axios.get(`${process.env.REACT_APP_API_URL}/charity_action/${charityActionId}`)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorFetch: false,
						proceedFetch: false,
						successFetch: true
					});

					this.setState({
						initialized: true,
						name: res.message.name,
						integrationId: res.message.integration_id,
						shortDescription: res.message.short_description,
						widgetText: res.message.widget_text,
						description: res.message.description,
						now: res.message.now,
						goal: res.message.goal,
						renewable: res.message.renewable === '1',
						expired: res.message.expired
					});

					setTimeout(() => {
						this.setState({
							successFetch: false
						});
					}, 2500);
				} else {
					this.setState({
						errorFetch: true,
						proceedFetch: false,
						successFetch: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorFetch: true,
					proceedFetch: false,
					successFetch: false
				});
			});

		return true;
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const {
			name,
			integrationId,
			shortDescription,
			renewable,
			description,
			now,
			goal,
			expired,
			proceedUpdate,
			proceedFetch,
			proceedIntegrationFetch,
			widgetText
		} = this.state;
		if (proceedUpdate || proceedFetch || proceedIntegrationFetch) return false;

		if (name === '' || description === '' || goal === '' || integrationId === '') {
			this.setState({
				errorUpdate: true,
				proceedUpdate: false,
				successUpdate: false
			});

			return false;
		}

		const {charityActionId} = this.props.match.params;

		let data;

		if (Number.isNaN((new Date(expired)).getDate()) || new Date(expired) < new Date()) {
			data = {
				name,
				integration_id: integrationId,
				widget_text: widgetText,
				short_description: shortDescription,
				description,
				now,
				goal,
				renewable
			};
		} else {
			data = {
				name,
				integration_id: integrationId,
				widget_text: widgetText,
				short_description: shortDescription,
				description,
				now,
				goal,
				renewable,
				expired
			};
		}

		this.setState({
			errorUpdate: false,
			proceedUpdate: true,
			successUpdate: false
		});

		axios.post(`${process.env.REACT_APP_API_URL}/charity_action/update/${charityActionId}`, data)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorUpdate: false,
						proceedUpdate: false,
						successUpdate: true
					});

					setTimeout(() => {
						window.location.replace('/charity_action');
					}, 2000);
				} else {
					this.setState({
						errorUpdate: true,
						proceedUpdate: false,
						successUpdate: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorUpdate: true,
					proceedUpdate: false,
					successUpdate: false
				});
			});

		return true;
	};

	loadIntegrations = () => {
		this.setState({
			errorIntegrationFetch: false,
			proceedIntegrationFetch: true,
			successIntegrationFetch: false
		});

		axios.get(`${process.env.REACT_APP_API_URL}/integration/`)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorIntegrationFetch: false,
						proceedIntegrationFetch: false,
						successIntegrationFetch: true
					});

					this.setState({
						integrations: res.message
					});

					setTimeout(() => {
						this.setState({
							successIntegrationFetch: false
						});
					}, 2500);
				} else {
					this.setState({
						errorIntegrationFetch: true,
						proceedIntegrationFetch: false,
						successIntegrationFetch: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorIntegrationFetch: true,
					proceedIntegrationFetch: false,
					successIntegrationFetch: false
				});
			});

		return true;
	};

	handleChange = (e) => {
		switch (e.target.name) {
			case 'renewable':
				this.setState({
					[e.target.name]: e.target.checked
				});
				break;

			default:
				this.setState({
					[e.target.name]: e.target.value
				});
				break;
		}
	};

	handleEditorChange = (value) => {
		this.setState({
			description: value
		});
	};

	render() {
		const {
			initialized,
			successFetch,
			proceedFetch,
			errorFetch,
			errorIntegrationFetch,
			proceedIntegrationFetch,
			successIntegrationFetch,
			successUpdate,
			proceedUpdate,
			errorUpdate,
			name,
			integrationId,
			shortDescription,
			now,
			goal,
			expired,
			description,
			renewable,
			widgetText,
			integrations
		} = this.state;
		const {charityActionId} = this.props.match.params;

		return (
			<LayoutPrivate>
				<Row>
					<Col md={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>
							Edycja zbiórki #{charityActionId}
						</h2>
						{
							(errorFetch || errorIntegrationFetch) && (
								<Alert variant="danger">
									Błąd połączenia z serwerem.
								</Alert>
							)
						}
						{
							(proceedFetch || proceedIntegrationFetch) && (
								<Alert variant="warning">
									Trwa ładowanie danych, daj systemowi trochę czasu :)
								</Alert>
							)
						}
						{
							(successFetch || successIntegrationFetch) && (
								<Alert variant="success">
									Gotowe, możesz pracować :)
								</Alert>
							)
						}
						{
							errorUpdate && (
								<Alert variant="danger">
									Popraw dane w formularzu, gdyż wystąpił błąd.
								</Alert>
							)
						}
						{
							proceedUpdate && (
								<Alert variant="warning">
									Aktualizowanie zbiórki w toku...
								</Alert>
							)
						}
						{
							successUpdate && (
								<Alert variant="success">
									Zbiórka została zaktualizowana, trwa przekierowanie...
								</Alert>
							)
						}
						{
							initialized && (
								<Form onSubmit={this.handleSubmit}>
									<Form.Group controlId="name">
										<Form.Label>Nazwa zbiórki</Form.Label>
										<Form.Control defaultValue={name} name="name" type="text" placeholder="Wpisz nazwę zbiórki" onChange={this.handleChange} />
									</Form.Group>

									<Form.Group controlId="integrationId">
										<Form.Label>Integracja</Form.Label>
										<Form.Control name="integrationId" as="select" onChange={this.handleChange}>
											<option value="">Wybierz integrację</option>
											{
												integrations && integrations.map((i) => (
													<option key={`integration_${i.id}`} value={i.id} selected={integrationId === i.id}>{i.name}</option>
												))
											}
										</Form.Control>
									</Form.Group>

									<Form.Group controlId="widget-text">
										<Form.Label>Tekst na widget (opcjonalnie)</Form.Label>
										<Form.Control as="textarea" rows="3" placeholder="Tekst będzie używany do generowania widgetu" name="widgetText" defaultValue={widgetText} onChange={this.handleChange} />
									</Form.Group>

									<Form.Group controlId="short-description">
										<Form.Label>Krótki opis</Form.Label>
										<Form.Control as="textarea" rows="6" placeholder="Krótki opis położony u góry" name="shortDescription" defaultValue={shortDescription} onChange={this.handleChange} />
									</Form.Group>

									<div style={{marginBottom: '1rem'}}>
										<Form.Label>Pełny opis</Form.Label>
										<Editor
											init={{
												plugins: ['advlist autolink autosave lists link image charmap print preview hr anchor pagebreak',
													'searchreplace wordcount visualblocks visualchars code fullscreen',
													'insertdatetime media nonbreaking save table contextmenu directionality',
													'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc help'],
												toolbar1: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
												toolbar2: 'print preview media | forecolor backcolor emoticons | codesample help',
												menubar: false,
												image_advtab: true,
												branding: false,
												height: 400
											}}
											apiKey="dzvcovd8edsd49trppvulpgvdhlujjbv48dzrb8t22j34qk2"
											name="description"
											value={description}
											onEditorChange={this.handleEditorChange}
										/>
									</div>

									<Row>
										<Col lg={{span: 6}}>
											<Form.Group controlId="now">
												<Form.Label>Uzbierano (zł)</Form.Label>
												<Form.Control defaultValue={now} name="now" type="number" placeholder="Wpisz kwotę zebraną" onChange={this.handleChange} disabled />
											</Form.Group>
										</Col>
										<Col lg={{span: 6}}>
											<Form.Group controlId="goal">
												<Form.Label>Cel (zł)</Form.Label>
												<Form.Control defaultValue={goal} name="goal" type="number" placeholder="Wpisz kwotę celu" onChange={this.handleChange} />
											</Form.Group>
										</Col>
									</Row>

									<Form.Group controlId="expired">
										<Form.Label>Data zakończenia zbiórki (opcjonalnie)</Form.Label>
										<Form.Control defaultValue={expired} name="expired" type="date" placeholder="Wprowadź datę" onChange={this.handleChange} />
									</Form.Group>

									<Form.Group controlId="renewable">
										<Form.Check name="renewable" type="checkbox" label="Zdefiniuj zbiórkę jako odnawialną (pierwszego dnia miesiąca)" onChange={this.handleChange} defaultChecked={renewable} />
									</Form.Group>

									<Button variant="primary" type="submit">
										Zapisz zbiórkę
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

EditCharityAction.propTypes = {
	match: PropTypes.object.isRequired
};

export default EditCharityAction;
