import React from 'react';
import {
	Navbar,
	Nav,
	Container,
	NavDropdown
} from 'react-bootstrap';

const logout = () => {
	window.localStorage.removeItem('token');
	window.location.replace('/');
};

const NavPrivate = () => (
	<Navbar bg="light" expand="lg">
		<Container>
			<Navbar.Brand href="/">{process.env.REACT_APP_NAME}</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="mr-auto">
					<Nav.Link href="/charity_action">Zbiórki</Nav.Link>
					<Nav.Link href="/sponsorship">Wspierający</Nav.Link>
					<Nav.Link href="/charity/all">Wpłaty</Nav.Link>
				</Nav>
				<Nav>
					<Nav.Link href="/integration">Integracje</Nav.Link>
					<NavDropdown title="Moje konto" id="account-nav-dropdown">
						<NavDropdown.Item href="/account/profile">Zarządzanie kontem</NavDropdown.Item>
						<NavDropdown.Item href="/account">Konta użytkowników</NavDropdown.Item>
						<NavDropdown.Divider />
						<NavDropdown.Item href={`https://zacznijto.pl/support?app=crowdfunding-v${process.env.REACT_APP_VERSION}&view=${encodeURIComponent(window.location.href)}`}>Pomoc techniczna</NavDropdown.Item>
						<NavDropdown.Divider />
						<NavDropdown.Item href="#" onClick={logout}>Wyloguj</NavDropdown.Item>
					</NavDropdown>
				</Nav>
			</Navbar.Collapse>
		</Container>
	</Navbar>
);

export default NavPrivate;
