import React, {Component} from 'react';
import axios from 'axios';
import _ from 'lodash';

import {
	Modal, Table, Button, Row, Col, Alert, Form
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import LayoutPrivate from '../../../components/Layout/Private';

import {extendToken, generateCharityType, setTitle} from '../../../util/common';

class AllCharity extends Component {
	state = {
		success: false,
		proceed: false,
		error: false,
		successDeletion: false,
		proceedDeletion: false,
		errorDeletion: false,
		successCsv: false,
		proceedCsv: false,
		errorCsv: false,
		deleteId: null,
		data: [],
		order: 'created',
		asc: false,
		filter: {
			date: {
				from: null,
				to: null
			},
			price: {
				from: null,
				to: null
			},
			email: null
		}
	};

	constructor(props) {
		super(props);

		this.countdown = null;
	}

	componentDidMount() {
		extendToken();

		setTitle(['Wpłaty', 'Wszystkie']);

		this.loadData();
	}

	handleChange = (e) => {
		const el = e.target;

		this.setState((prevState) => {
			const newState = _.assign({}, prevState);
			return _.set(newState, el.name, el.value);
		}, () => {
			clearTimeout(this.countdown);

			this.countdown = setTimeout(() => {
				this.loadData();
			}, 500);
		});
	};

	sortable = (type) => {
		this.setState((prevState) => ({
			order: type,
			asc: prevState.order === type ? !prevState.asc : prevState.asc
		}), () => {
			this.loadData();
		});
	};

	loadData = () => {
		this.setState({
			error: false,
			proceed: true,
			success: false
		});

		const {order, asc, filter} = this.state;
		const params = {
			order,
			asc,
			filter
		};

		axios.get(`${process.env.REACT_APP_API_URL}/charity/`, {params})
			.then((res) => {
				if (res.success) {
					this.setState({
						error: false,
						proceed: false,
						success: true
					});

					this.setState({
						data: res.message
					});

					setTimeout(() => {
						this.setState({
							success: false
						});
					}, 2500);
				} else {
					this.setState({
						error: true,
						proceed: false,
						success: false
					});
				}
			})
			.catch(() => {
				this.setState({
					error: true,
					proceed: false,
					success: false
				});
			});

		return true;
	};

	handleDelete = (deleteId) => {
		this.setState({
			deleteId
		});
	};

	handleDeleteConfirmed = () => {
		const {deleteId, proceedDeletion, proceed} = this.state;

		if (proceedDeletion || proceed) {
			return false;
		}

		this.setState({
			deleteId: null,
			errorDeletion: false,
			proceedDeletion: true,
			successDeletion: false
		});

		axios.delete(`${process.env.REACT_APP_API_URL}/charity/${deleteId}`)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorDeletion: false,
						proceedDeletion: false,
						successDeletion: true
					});

					this.loadData();

					setTimeout(() => {
						this.setState({
							successDeletion: false
						});
					}, 2000);
				} else {
					this.setState({
						errorDeletion: true,
						proceedDeletion: false,
						successDeletion: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorDeletion: true,
					proceedDeletion: false,
					successDeletion: false
				});
			});

		return true;
	};

	handleDeleteClose = () => {
		this.setState({
			deleteId: null
		});
	};

	getCsv = () => {
		const {proceedCsv} = this.state;

		if (proceedCsv) {
			return false;
		}

		this.setState({
			errorCsv: false,
			proceedCsv: true,
			successCsv: false
		});

		const {order, asc, filter} = this.state;
		const params = {
			order,
			asc,
			filter
		};

		axios.get(`${process.env.REACT_APP_API_URL}/charity/`, {params})
			.then((res) => {
				if (res.success) {
					this.setState({
						errorCsv: false,
						proceedCsv: false,
						successCsv: true
					});

					window.location.href = res.message;

					setTimeout(() => {
						this.setState({
							successCsv: false
						});
					}, 2000);
				} else {
					this.setState({
						errorCsv: true,
						proceedCsv: false,
						successCsv: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorCsv: true,
					proceedCsv: false,
					successCsv: false
				});
			});

		return true;
	};

	render() {
		const {history} = this.props;
		const {
			data, success, proceed, error, successDeletion, proceedDeletion, errorDeletion, successCsv, proceedCsv, errorCsv, deleteId, order, asc
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col>
						<h2 style={{marginBottom: '2rem'}}>Wszystkie wpłaty</h2>
						{
							errorDeletion && (
								<Alert variant="danger">
									Błąd połączenia z serwerem, obiekt nie został usunięty.
								</Alert>
							)
						}
						{
							proceedDeletion && (
								<Alert variant="warning">
									Trwa usuwanie wybranego obiektu, proszę czekać...
								</Alert>
							)
						}
						{
							successDeletion && (
								<Alert variant="success">
									Obiekt został usunięty poprawnie.
								</Alert>
							)
						}
						{
							errorCsv && (
								<Alert variant="danger">
									Błąd połączenia z serwerem, plik nie został wygenerowany.
								</Alert>
							)
						}
						{
							proceedCsv && (
								<Alert variant="warning">
									Trwa generowanie pliku CSV, proszę czekać...
								</Alert>
							)
						}
						{
							successCsv && (
								<Alert variant="success">
									Plik został wygenerowany.
								</Alert>
							)
						}
						{
							error && (
								<Alert variant="danger">
									Błąd połączenia z serwerem.
								</Alert>
							)
						}
						{
							proceed && (
								<Alert variant="warning">
									Generowanie widoku w toku... System może częściowo nie odpowiadać.
								</Alert>
							)
						}
						{
							success && (
								<Alert variant="success">
									Gotowe, możesz pracować :)
								</Alert>
							)
						}
						<Row>
							<Col md={{span: 2}}>
								<Form.Label>Wpłaty od</Form.Label>
								<Form.Control name="filter.date.from" type="date" placeholder="Wprowadź datę" onChange={this.handleChange} />
							</Col>
							<Col md={{span: 2}}>
								<Form.Label>Wpłaty do</Form.Label>
								<Form.Control name="filter.date.to" type="date" placeholder="Wprowadź datę" onChange={this.handleChange} />
							</Col>
							<Col md={{span: 2}}>
								<Form.Label>Kwota od</Form.Label>
								<Form.Control name="filter.price.from" type="number" placeholder="Kwota od" onChange={this.handleChange} />
							</Col>
							<Col md={{span: 2}}>
								<Form.Label>Kwota do</Form.Label>
								<Form.Control name="filter.price.to" type="number" placeholder="Kwota do" onChange={this.handleChange} />
							</Col>
							<Col md={{span: 4}}>
								<Form.Label>E-mail</Form.Label>
								<Form.Control name="filter.email" type="text" placeholder="E-mail" onChange={this.handleChange} />
							</Col>
						</Row>
						<Table striped bordered hover style={{marginTop: '1rem'}}>
							<thead>
								<tr>
									<th colSpan="7" className="buttons">
										<Button variant="primary" href="/charity_action">Przejdź do prowadzonych zbiórek</Button>
										&nbsp;
										<Button variant="primary" onClick={this.getCsv}>Pobierz listę jako plik CSV</Button>
									</th>
								</tr>
								<tr>
									<th className="sortable" onClick={() => this.sortable('id')}>
										#
										{order === 'id' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('sponsor_email')}>
										E-mail
										{order === 'sponsor_email' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('charity_action_name')}>
										Zbiórka
										{order === 'charity_action_name' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('charity_type_name')}>
										Rodzaj
										{order === 'charity_type_name' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('price')}>
										Wysokość
										{order === 'price' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('created')}>
										Utworzono
										{order === 'created' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th />{/* eslint-disable-line jsx-a11y/control-has-associated-label */}
								</tr>
							</thead>
							<tbody>
								{
									data.map((item) => (
										<tr key={`table_${item.id}`}>
											<td>{item.id}</td>
											<td>{item.sponsor_email}</td>
											<td>{item.charity_action_name}</td>
											<td>{generateCharityType(item.charity_type_name)}</td>
											<td>
												{item.price}
												zł
											</td>
											<td>{item.created}</td>
											<td>
												<button type="button" className="btn btn-sm btn-success" onClick={() => history.push(`/charity/edit/${item.id}`)}>Edytuj</button>
												&nbsp;
												<button type="button" className="btn btn-sm btn-danger" onClick={() => this.handleDelete(item.id)}>Usuń</button>
											</td>
										</tr>
									))
								}
							</tbody>
						</Table>
					</Col>
				</Row>
				<Modal show={deleteId !== null} onHide={this.handleDeleteClose}>
					<Modal.Header closeButton>
						<Modal.Title>Potwierdź akcję</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>
							Naprawdę chcesz usunąć wpłatę #{deleteId}? To spowoduje obniżenie spełnienia celu zbiórki. Co za tym idzie pieniądze, które zostały zaksięgowane nie zostaną zwrócone, ale też nie zostaną udokumentowane na stronie zbiórki.
						</p>
						<Alert variant="warning">
							<strong>Zatrzymaj się na chwilę!</strong> Ta operacja jest nieodwracalna z poziomu panelu administratora. Może ją przywrócić tylko administrator systemu.
						</Alert>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="success" onClick={this.handleDeleteConfirmed}>
							Tak, chcę.
						</Button>
						<Button variant="danger" onClick={this.handleDeleteClose}>
							Nie, nie chcę.
						</Button>
					</Modal.Footer>
				</Modal>
			</LayoutPrivate>
		);
	}
}

AllCharity.propTypes = {
	history: PropTypes.object.isRequired
};

export default AllCharity;
