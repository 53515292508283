import React from 'react';
import {Navbar, Nav, Container} from 'react-bootstrap';

const NavPublic = () => (
	<Navbar bg="light" expand="lg">
		<Container>
			<Navbar.Brand href="/">{process.env.REACT_APP_NAME}</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="mr-auto">
					<Nav.Link href="/">Logowanie</Nav.Link>
					<Nav.Link href="/forgot-password">Przywracanie hasła</Nav.Link>
				</Nav>
				<Nav>
					<Nav.Link href={`https://zacznijto.pl/support?app=crowdfunding-v${process.env.REACT_APP_VERSION}&view=${encodeURIComponent(window.location.href)}`}>Pomoc techniczna</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Container>
	</Navbar>
);

export default NavPublic;
