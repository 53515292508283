import React, {Component} from 'react';
import axios from 'axios';

import {
	Table, Button, Row, Col, Alert
} from 'react-bootstrap';
import LayoutPrivate from '../../../components/Layout/Private';

import {extendToken, setTitle} from '../../../util/common';

class ListUnactiveSponsorship extends Component {
	state = {
		success: false,
		proceed: false,
		error: false,
		data: [],
		order: 'created',
		asc: false
	};

	componentDidMount() {
		extendToken();

		setTitle(['Cykliczne wpłaty', 'Nieaktywne']);

		this.loadData();
	}

	sortable = (type) => {
		this.setState((prevState) => ({
			order: type,
			asc: prevState.order === type ? !prevState.asc : prevState.asc
		}), () => {
			this.loadData();
		});
	};

	loadData = () => {
		this.setState({
			error: false,
			proceed: true,
			success: false
		});

		const {order, asc} = this.state;

		axios.get(`${process.env.REACT_APP_API_URL}/sponsorship/unactive?order=${order}&asc=${asc}`)
			.then((res) => {
				if (res.success) {
					this.setState({
						error: false,
						proceed: false,
						success: true
					});

					this.setState({
						data: res.message
					});

					setTimeout(() => {
						this.setState({
							success: false
						});
					}, 2500);
				} else {
					this.setState({
						error: true,
						proceed: false,
						success: false
					});
				}
			})
			.catch(() => {
				this.setState({
					error: true,
					proceed: false,
					success: false
				});
			});

		return true;
	};

	render() {
		const {
			data, success, proceed, error, order, asc
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col>
						<h2 style={{marginBottom: '2rem'}}>Nieaktywne cykliczne wpłaty</h2>
						{
							error && (
								<Alert variant="danger">
									Błąd połączenia z serwerem.
								</Alert>
							)
						}
						{
							proceed && (
								<Alert variant="warning">
									Generowanie widoku w toku... System może częściowo nie odpowiadać.
								</Alert>
							)
						}
						{
							success && (
								<Alert variant="success">
									Gotowe, możesz pracować :)
								</Alert>
							)
						}
						<Table striped bordered hover>
							<thead>
								<tr>
									<th colSpan="6" className="buttons">
										<Button variant="primary" href="/sponsorship">Przejdź do aktywnych cyklicznych wpłat</Button>
									</th>
								</tr>
								<tr>
									<th className="sortable" onClick={() => this.sortable('id')}>
										#
										{order === 'id' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('sponsor_email')}>
										E-mail
										{order === 'sponsor_email' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('charity_action_name')}>
										Zbiórka
										{order === 'charity_action_name' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('price')}>
										Wysokość
										{order === 'price' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('created')}>
										Utworzono
										{order === 'created' && (asc ? '[↑]' : '[↓]')}
									</th>
									<th className="sortable" onClick={() => this.sortable('updated')}>
										Ostatnia wpłata
										{order === 'updated' && (asc ? '[↑]' : '[↓]')}
									</th>
								</tr>
							</thead>
							<tbody>
								{
									data.map((item) => (
										<tr key={`table_${item.id}`}>
											<td>{item.id}</td>
											<td>{item.sponsor_email}</td>
											<td>{item.charity_action_name}</td>
											<td>
												{item.price}
												zł
											</td>
											<td>{item.created}</td>
											<td>{item.updated}</td>
										</tr>
									))
								}
							</tbody>
						</Table>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

export default ListUnactiveSponsorship;
