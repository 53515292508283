import React, {Component} from 'react';
import axios from 'axios';
import {Editor} from '@tinymce/tinymce-react';

import {
	Form, Button, Row, Col, Alert
} from 'react-bootstrap';
import LayoutPrivate from '../../../components/Layout/Private';

import {extendToken, setTitle} from '../../../util/common';

class AddCharityAction extends Component {
	state = {
		success: false,
		proceed: false,
		error: false,
		successIntegrationFetch: false,
		proceedIntegrationFetch: false,
		errorIntegrationFetch: false,
		integrations: null,
		name: '',
		integrationId: '',
		shortDescription: '',
		widgetText: '',
		description: '',
		now: '0',
		goal: '',
		expired: '',
		renewable: false
	};

	componentDidMount() {
		extendToken();

		setTitle(['Zbiórki', 'Dodaj nową zbiórkę']);

		this.loadIntegrations();
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const {
			name,
			integrationId,
			shortDescription,
			widgetText,
			renewable,
			description,
			now,
			goal,
			expired,
			proceed,
			proceedIntegrationFetch
		} = this.state;
		if (proceed || proceedIntegrationFetch) return false;

		if (name === '' || description === '' || goal === '' || integrationId === '') {
			this.setState({
				error: true,
				proceed: false,
				success: false
			});

			return false;
		}

		let data;

		if (Number.isNaN((new Date(expired)).getDate()) || new Date(expired) < new Date()) {
			data = {
				name,
				integration_id: integrationId,
				widget_text: widgetText,
				short_description: shortDescription,
				description,
				now,
				renewable,
				goal
			};
		} else {
			data = {
				name,
				integration_id: integrationId,
				widget_text: widgetText,
				short_description: shortDescription,
				description,
				now,
				goal,
				renewable,
				expired
			};
		}

		this.setState({
			error: false,
			proceed: true,
			success: false
		});

		axios.post(`${process.env.REACT_APP_API_URL}/charity_action/create`, data)
			.then((res) => {
				if (res.success) {
					this.setState({
						error: false,
						proceed: false,
						success: true
					});

					setTimeout(() => {
						window.location.replace('/charity_action');
					}, 2000);
				} else {
					this.setState({
						error: true,
						proceed: false,
						success: false
					});
				}
			})
			.catch(() => {
				this.setState({
					error: true,
					proceed: false,
					success: false
				});
			});

		return true;
	};

	loadIntegrations = () => {
		this.setState({
			errorIntegrationFetch: false,
			proceedIntegrationFetch: true,
			successIntegrationFetch: false
		});

		axios.get(`${process.env.REACT_APP_API_URL}/integration/`)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorIntegrationFetch: false,
						proceedIntegrationFetch: false,
						successIntegrationFetch: true
					});

					this.setState({
						integrations: res.message
					});

					setTimeout(() => {
						this.setState({
							successIntegrationFetch: false
						});
					}, 2500);
				} else {
					this.setState({
						errorIntegrationFetch: true,
						proceedIntegrationFetch: false,
						successIntegrationFetch: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorIntegrationFetch: true,
					proceedIntegrationFetch: false,
					successIntegrationFetch: false
				});
			});

		return true;
	};

	handleChange = (e) => {
		switch (e.target.name) {
			case 'renewable':
				this.setState({
					[e.target.name]: e.target.checked
				});
				break;

			default:
				this.setState({
					[e.target.name]: e.target.value
				});
				break;
		}
	};

	handleEditorChange = (value) => {
		this.setState({
			description: value
		});
	};

	render() {
		const {
			success,
			proceed,
			error,
			successIntegrationFetch,
			proceedIntegrationFetch,
			errorIntegrationFetch,
			integrationId,
			description,
			integrations
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col md={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowa zbiórka</h2>
						{
							errorIntegrationFetch && (
								<Alert variant="danger">
									Błąd połączenia z serwerem.
								</Alert>
							)
						}
						{
							proceedIntegrationFetch && (
								<Alert variant="warning">
									Generowanie widoku w toku... System może częściowo nie odpowiadać.
								</Alert>
							)
						}
						{
							successIntegrationFetch && (
								<Alert variant="success">
									Gotowe, możesz pracować :)
								</Alert>
							)
						}
						{
							error && (
								<Alert variant="danger">
									Popraw dane w formularzu, gdyż wystąpił błąd.
								</Alert>
							)
						}
						{
							proceed && (
								<Alert variant="warning">
									Zakładanie zbiórki w toku...
								</Alert>
							)
						}
						{
							success && (
								<Alert variant="success">
									Zbiórka utworzona, trwa przekierowanie...
								</Alert>
							)
						}
						<Form onSubmit={this.handleSubmit}>
							<Form.Group controlId="name">
								<Form.Label>Nazwa zbiórki</Form.Label>
								<Form.Control name="name" type="text" placeholder="Wpisz nazwę zbiórki" onChange={this.handleChange} />
							</Form.Group>

							<Form.Group controlId="integrationId">
								<Form.Label>Integracja</Form.Label>
								<Form.Control name="integrationId" as="select" onChange={this.handleChange}>
									<option value="">Wybierz integrację</option>
									{
										integrations && integrations.map((i) => (
											<option key={`integration_${i.id}`} value={i.id} selected={integrationId === i.id}>{i.name}</option>
										))
									}
								</Form.Control>
							</Form.Group>

							<Form.Group controlId="widget-text">
								<Form.Label>Tekst na widget (opcjonalnie)</Form.Label>
								<Form.Control as="textarea" rows="3" placeholder="Tekst będzie używany do generowania widgetu" name="widgetText" onChange={this.handleChange} />
							</Form.Group>

							<Form.Group controlId="short-description">
								<Form.Label>Krótki opis</Form.Label>
								<Form.Control as="textarea" rows="6" placeholder="Krótki opis położony u góry" name="shortDescription" onChange={this.handleChange} />
							</Form.Group>

							<div style={{marginBottom: '1rem'}}>
								<Form.Label>Pełny opis</Form.Label>
								<Editor
									init={{
										plugins: ['advlist autolink autosave lists link image charmap print preview hr anchor pagebreak',
											'searchreplace wordcount visualblocks visualchars code fullscreen',
											'insertdatetime media nonbreaking save table contextmenu directionality',
											'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc help'],
										toolbar1: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
										toolbar2: 'print preview media | forecolor backcolor emoticons | codesample help',
										menubar: false,
										image_advtab: true,
										branding: false,
										height: 400
									}}
									apiKey="dzvcovd8edsd49trppvulpgvdhlujjbv48dzrb8t22j34qk2"
									name="description"
									value={description}
									onEditorChange={this.handleEditorChange}
								/>
							</div>

							<Row>
								<Col lg={{span: 6}}>
									<Form.Group controlId="now">
										<Form.Label>Uzbierano (zł)</Form.Label>
										<Form.Control name="now" type="number" defaultValue="0" placeholder="Wpisz kwotę zebraną" onChange={this.handleChange} disabled />
									</Form.Group>
								</Col>
								<Col lg={{span: 6}}>
									<Form.Group controlId="goal">
										<Form.Label>Cel (zł)</Form.Label>
										<Form.Control name="goal" type="number" placeholder="Wpisz kwotę celu" onChange={this.handleChange} />
									</Form.Group>
								</Col>
							</Row>

							<Form.Group controlId="expired">
								<Form.Label>Data zakończenia zbiórki (opcjonalnie)</Form.Label>
								<Form.Control name="expired" type="date" placeholder="Wprowadź datę" onChange={this.handleChange} />
							</Form.Group>

							<Form.Group controlId="renewable">
								<Form.Check name="renewable" type="checkbox" label="Zdefiniuj zbiórkę jako odnawialną (pierwszego dnia miesiąca)" onChange={this.handleChange} />
							</Form.Group>

							<Button variant="primary" type="submit">
								Utwórz nową zbiórkę
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

export default AddCharityAction;
